import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { features } from '@app/core/startup/permission';
import { Feature, Permission } from '@app/interfaces/sys/permission';
import { environment } from '@env/environment';
import { LicenceService } from '@services/sys/licence.service';
import { Menu } from '@app/interfaces/sys/menu';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    allLicencesKeys: Set<string> = new Set();
    expiredKeys: string[] = [];
    expiredPermissions: string[] = [];
    constructor(private http: HttpClient, private licenceService: LicenceService) {
    }

    async find(): Promise<Permission[]> {
        return this.http.get<Permission[]>('/permissions/all').toPromise();
    }

    // get the feature list when the licence is valid
    getValidFeatureList(): Feature[] {
        const licences = JSON.parse(localStorage.getItem('licences') || '[]');
        const { allKeys, expiredKeys } = this.licenceService.getListFeatureKey(licences);
        this.expiredKeys = expiredKeys;
        this.allLicencesKeys = allKeys;

        const featuresCopy = JSON.stringify(this.getAllFeatures() as Feature[]);
        const featuresCopyFiltered = this.licenceService.filterFeaturesByKeys(JSON.parse(featuresCopy), allKeys);
        const processedFeatures = this.licenceService.addChildPermission(featuresCopyFiltered, allKeys);

        const validFeatures: Feature[] = this.getValidFeature(processedFeatures, allKeys);
        return validFeatures;
    }

    // use licence filter valid feature and sub feature
    getValidFeature(featuresData: Feature[], keyList: Set<string>): Feature[] {
        // const newArr = featuresData.filter(it => !it.key || keyList.has(it.key));
        return featuresData.map(item => {
            if (item.children) {
                item.children = this.getValidFeature(item.children, keyList);
            }
            const isExpired = !!item?.key && this.expiredKeys.includes(item?.key);
            return {
                ...item,
                expired: isExpired
            };
        });
    }

    getAllFeatures(): Feature[] {
        const enablePages = environment.enablePages;
        return features.filter(item => !item.key || enablePages.includes(item.key));
    }

    getExpiredPermissions(): string[] {
        const allFeatures: Feature[] = this.getValidFeatureList();
        this.expiredPermissions = [];
        this.getExpiredSet(allFeatures);
        return this.expiredPermissions;
    }

    getExpiredSet(featureItem: Feature[], parentExpired: boolean = false): void {
        featureItem.forEach((it: Feature) => {
            const expired = it?.expired;
            if (!it.children || it.children.length === 0) {
                if (!expired && !parentExpired) {
                    return;
                }
                this.expiredPermissions.push(it.name);
            } else {
                this.getExpiredSet(it.children as Feature[], expired);
            }
        });
    }

    isValidMenu(menuName: string): boolean {
        const data = sessionStorage.getItem('menus');
        const menus: Menu[] = JSON.parse(data || '[]');
        return !!menus?.find(x => x?.text === menuName && !x?.hide && !x?.disabled);
    }
}
