import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges, ViewChild,
} from '@angular/core';
import { Tab } from '@app/interfaces/lm/system';
import {
    ApprovalSetting,
    CalendarOneData,
    CalendarResource,
    CalendarTableData, CalendarTableRow, CalendarVOList, CellReservation,
    ReservationAdd, ReservationCancel,
    ResourceParams,
    ResourceTree,
} from '@app/interfaces/rm/resource-reservation';
import { STScroll } from '@app/interfaces/sys/st';
import { ResourceReservationService } from '@services/rm/resource-reservation.service';
import { CommonService } from '@services/sys/common.service';
import { SettingsService } from '@services/sys/settings.service';
import { TokenService } from '@services/sys/token.service';
import dayjs, { Dayjs, OpUnitType, UnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { EditReservationComponent } from './component/edit-reservation/edit-reservation.component';
import { ReservationInfosComponent } from './component/reservation-infos/reservation-infos.component';
import { ReserveUserSelectComponent } from './component/reserve-user-select/reserve-user-select.component';

@Component({
    selector: 'app-table-calendar',
    templateUrl: './table-calendar.component.html',
    styleUrls: ['./table-calendar.component.less'],
})
export class TableCalendarComponent implements OnInit, OnChanges {
    @ViewChild('editDialog') editDialog!: EditReservationComponent;
    @ViewChild('calendarTable') calendarTableEl!: ElementRef;
    @ViewChild('table', { static: false }) table!: NzTableComponent<any>;
    @ViewChild('infoCard') infoCard!: ReservationInfosComponent;
    @Input() canEdit = true;
    @Input() isSelf = false;
    @Input() isError = false;
    @Input() resources: CalendarResource[] = [];
    @Input() timeRange = [dayjs().startOf('month').valueOf(), dayjs().endOf('month').valueOf()];
    @Input() calendarTableData: CalendarTableData = {
        resources: [],
        reservations: [],
        reservationInfo: [],
    };
    @Output() changeRange = new EventEmitter();
    @Output() changeFilter = new EventEmitter();
    @Output() submitSuccess = new EventEmitter();

    calendarMode = 'week';
    currentDay = '';
    dateList: string[] = [];
    tabs: Tab[] = [
        {
            id: 'day',
            name: 'Daily',
        },
        {
            id: 'week',
            name: 'Weekly',
        },
        {
            id: 'month',
            name: 'Monthly',
        },
    ];
    realStartTime = '';
    realEndTime = '';
    tableData: any[] = [];
    nzScroll: STScroll = {};
    selectDate: string[] = [];
    selectRowName = '';
    selectType = '';
    loading = false;
    startCell = '';
    currentAction = '';
    calendarLoading = false;
    reservations!: any[];
    resourceType = 'hardware';
    cellWidth = 60;
    start!: number;
    end!: number;
    nodes: ResourceTree[] = [];
    resourceTypes: ResourceTree[] = [];
    filterVisible = false;
    filterLoading = false;
    startInfo!: CalendarOneData | null;
    approvalSetting: ApprovalSetting[] = [];
    currentUserId!: number;
    canEnterCell = true;
    hasUEPermission = true;
    defaultNameWidth = 180;
    maxNameWidth = 180;
    hasRequestApprovalPermission = false;
    calendarEditPermissionAll = false;
    timer!: number;
    cancelOrReleaseReservationData!: CalendarVOList;
    dragCanUsedReserved: CalendarVOList[] = [];
    dragCanUsedRequest: CalendarVOList[] = [];

    constructor(private modal: NzModalService,
                private settingService: SettingsService,
                private tokenService: TokenService,
                private message: NzMessageService,
                private commonService: CommonService,
                private resourceReservationService: ResourceReservationService) {
    }

    get currentIndex(): number {
        if (this.calendarMode?.length) {
            return this.tabs.findIndex((item) => item.id === this.calendarMode);
        }
        return 0;
    }

    get isDayMode(): boolean {
        return this.calendarMode === 'day';
    }

    ngOnInit(): void {
        this.hasUEPermission = this.settingService.hasPermission(['UE Control View']) && !this.settingService.hasExpiredPermission(['UE Control']);
        this.hasRequestApprovalPermission = this.settingService.hasPermission(['Approval Edit']) && !this.settingService.hasExpiredPermission(['Approval Edit']);
        this.calendarEditPermissionAll = this.settingService.hasPermission(['Calendar Edit']) && !this.settingService.hasExpiredPermission(['Calendar Edit']) && !this.tokenService.IsSelfPermission('Calendar Edit');
        const info = this.tokenService.get();
        this.currentUserId = info?.id;
        this.resourceTypes = this.resourceReservationService.getResourceTypes().filter(it => this.hasUEPermission ? it : it.key !== 'UE');
        this.initTree();
        this.setScroll();
        this.resourceReservationService.subscribeEvent('calendarRequestStatusChangeFinish').subscribe((data) => {
            this.loading = false;
            this.editRefresh();
        });
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes?.timeRange || changes?.resources) {
            setTimeout(async () => {
                if (changes?.timeRange) {
                    this.start = this.timeRange[0];
                    this.end = this.timeRange[1];
                    this.dateList = await this.getDateList();
                    if (!this.dateList || this.dateList.length === 0) {
                        return;
                    }
                }
                if (changes?.resources) {
                    await this.getResourceList();
                    await this.getApprovalSetting();
                }
            }, 100);
        }
    }

    @HostListener('window:resize')
    _resize(): void {
        this.setScroll();
        this.setTableCell();
    }

    setTableCell(): void {
        const tableCell = document.getElementsByClassName('half-cell');
        if (tableCell.length === 0) {
            return;
        }
        // tslint:disable-next-line:forin
        for (const tableCellKey in tableCell) {
            const cellWidth = tableCell[tableCellKey]?.parentElement?.clientWidth;
            const cellHeight = tableCell[tableCellKey]?.parentElement?.clientHeight;
            if (!cellWidth || !cellHeight) {
                return;
            }
            const borderRightWidth = cellWidth / 2;
            const borderTopWidth = cellHeight / 2;
            tableCell[tableCellKey].setAttribute('style',
                `border-right-width: ${borderRightWidth}px;
                       border-left-width: ${borderRightWidth}px;
                       border-top-width: ${borderTopWidth}px;
                       border-bottom-width: ${borderTopWidth}px;`);
        }
    }

    setScroll(): void {
        this.settingService.setLayout();
        const { y } = this.settingService.layout;
        const height = this.calendarTableEl?.nativeElement?.clientHeight;
        this.nzScroll = {
            x: '300px',
            y: `${height ? height - 109 : y - 68}px`,
        };
    }

    async initTree(): Promise<void> {
        try {
            this.filterLoading = true;
            const nodes: ResourceTree[] = [];
            const typeList = this.resourceTypes.map(it => it.key);
            const params = {
                startTime: this.timeRange[0],
                endTime: this.timeRange[1],
                typeList
            };
            const res = this.isSelf ? await this.resourceReservationService.getCalendarListSelf(params) :
                await this.resourceReservationService.getCalendarList(params);
            for (const it of this.resourceTypes) {
                const { key } = it;
                const calendarData = res.find(item => item.type === key);
                const calendarList = calendarData ? (calendarData?.resourceCalendarList || []) : [];
                const children = calendarList.map((x) => {
                    return {
                        ...x,
                        title: x.name,
                        key: `${key}_${x.id}`,
                        checked: true,
                        isLeaf: true
                    };
                });
                nodes.push({
                    ...it,
                    expanded: true,
                    children,
                    checked: true,
                    isLeaf: children.length === 0,
                });
            }
            this.nodes = nodes;
            this.changeFilter.emit();
        } finally {
            this.filterLoading = false;
        }
    }

    getDisabled(type: string): boolean {
        if (type === 'pre') {
            return !dayjs(this.currentDay).isAfter(this.realStartTime);
        }

        if (type === 'next') {
            return !dayjs(this.currentDay).isBefore(this.realEndTime);
        }
        return false;
    }

    changeCalendarRange(type: string): void {
        let startTime;
        let endTime;
        const unit = this.calendarMode === 'month' ? 'month' : 'week';
        if (type === 'pre') {
            startTime = dayjs(this.start).subtract(1, unit).startOf(unit);
            endTime = dayjs(this.start).subtract(1, unit).endOf(unit);
        } else {
            startTime = dayjs(this.start).add(1, unit).startOf(unit);
            endTime = dayjs(this.start).add(1, unit).endOf(unit);
        }
        this.start = dayjs(startTime).valueOf();
        this.end = dayjs(endTime).valueOf();
        this.changeRange.emit([this.start, this.end]);
    }

    getDayRange(): string {
        const start = dayjs(this.start).startOf('d').valueOf();
        const end = dayjs(this.end).startOf('d').valueOf();
        // if (start !== end && this.fromResourceCalendar) {
        //     return dayjs(this.start).format('YYYY-MM-DD') + ' - ' + dayjs(this.end).format('YYYY-MM-DD h A');
        // } else {
        //     return dayjs(this.currentDay).format('YYYY-MM-DD');
        // }
        return dayjs(this.currentDay).format('YYYY-MM-DD');
    }

    async changeDay(type: string): Promise<void> {
        if (type === 'pre') {
            this.currentDay = dayjs(this.currentDay).subtract(1, 'day').format('YYYY-MM-DD');
        } else {
            this.currentDay = dayjs(this.currentDay).add(1, 'day').format('YYYY-MM-DD');
        }
        const range: number[] = [dayjs(this.currentDay).startOf('day').valueOf(), dayjs(this.currentDay).endOf('day').valueOf()];
        this.changeRange.emit(range);
    }

    async getDateList(): Promise<string[]> {
        const start = this.start;
        const end = this.end;
        if (!this.isDayMode) {
            this.realStartTime = dayjs(start).format('YYYY-MM-DD');
            this.realEndTime = dayjs(end).format('YYYY-MM-DD');

            const diff = dayjs(this.realEndTime).diff(this.realStartTime, 'days');
            const calendarRange = [];
            for (let i = 0; i <= diff; i++) {
                calendarRange.push(dayjs(this.realStartTime).add(i, 'd').format('YYYY-MM-DD'));
            }
            return calendarRange;
        } else {
            const calendarRange = [];
            const diff = 24;
            for (let i = 0; i < diff; i++) {
                calendarRange.push(dayjs(this.currentDay).add(i, 'hour').format('YYYY-MM-DD HH:mm:ss'));
            }
            return calendarRange;
        }
    }

    async getApprovalSetting(): Promise<void> {
        if (!this.isError && this.resources && this.resources.length) {
            this.approvalSetting = await this.resourceReservationService.getApprovalSetting();
        }
    }

    formatTime(date: number | Date | Dayjs | string = new Date(), formatter: string = 'YYYY-MM-DD HH:mm:ss'): string {
        return dayjs(date).format(formatter);
    }

    get cellUnit(): OpUnitType {
        return this.isDayMode ? 'h' : 'd';
    }

    getResourceList(): void {
        dayjs.extend(isBetween);
        console.log('resources', this.resources);
        this.tableData = !this.resources || this.resources.length === 0 ? [] : this.resources.map((resourceItem) => {
            const reservationData = resourceItem?.calendarVOList || [];
            let dateList = [];
            dateList = this.dateList.map((dateItem) => {
                const reservationInfos: CalendarVOList[] = [];
                reservationData?.forEach((data) => {
                    const { status } = data;
                    if (status?.toUpperCase() === 'REJECTED') {
                        return;
                    }
                    const rangeStart = this.formatTime(data.startTime);
                    const rangeEnd = this.formatTime(data.endTime);

                    const rangeIncludeDate = dayjs(dateItem).isBetween(rangeStart, rangeEnd) ||
                        (this.isDayMode && (this.isSameDay(dateItem, rangeStart, 'YYYY-MM-DD HH') || this.isSameDay(dateItem, rangeEnd, 'YYYY-MM-DD HH'))) ||
                        (!this.isDayMode && (this.isSameDay(dateItem, rangeStart) || this.isSameDay(dateItem, rangeEnd)));
                    if (!rangeIncludeDate) {
                        return;
                    }
                    const dateItemStart = this.formatTime(dayjs(dateItem).startOf(this.cellUnit));
                    const dateItemEnd = this.formatTime(dayjs(dateItem).endOf(this.cellUnit));

                    const isSomeHour = dayjs(data.startTime).isBetween(dateItemStart, dateItemEnd) || dayjs(data.endTime).isBetween(dateItemStart, dateItemEnd);
                    reservationInfos.push({
                        ...data,
                        isSomeHour,
                    });
                });
                const weekNumber = dayjs(dateItem).day();
                const allIsSomeHour = reservationInfos.length > 0 && reservationInfos.every(item => item.isSomeHour);
                const { selfRequest, selfValidReserved, selfValidRequest, otherValidRequest,otherRequest, otherReserved, selfReserved, otherValidReserved } = this.getCellSelfReservation({
                    reservationData: reservationInfos
                });
                const requestReservations = [...selfRequest, ...otherRequest];
                const reservedReservations = [...selfReserved, ...otherReserved];
                return {
                    date: dateItem,
                    reservationData: reservationInfos,
                    isSomeHour: allIsSomeHour,
                    disabled: this.getDisabledDate(dateItem),
                    isWeekend: weekNumber === 6 || weekNumber === 0,
                    requestReservations,
                    reservedReservations,
                    validRequest: [...selfValidRequest, ...otherValidRequest],
                    validReserved: [...selfValidReserved, ...otherValidReserved],
                    isDiffUserReserved: selfValidReserved.length === 0 && otherValidReserved.length > 0,
                };
            });
            return {
                ...resourceItem,
                id: resourceItem.id,
                name: resourceItem.name,
                dateList,
            };
        });
        this.getCalendarNameWidth();
        setTimeout(() => {
            this.setTableCell();
            this.resetTableScroll();
        }, 200);
        this.refreshTableCell();
        if (this.timer) {
            return;
        }
        this.timer = setInterval(() => {
            this.refreshTableCell();
        }, 1000 * 10);
    }

    refreshTableCell(): void {
        const currentTime = this.getCurrentTime();
        this.tableData.forEach(item => {
            const { dateList } = item;
            dateList.forEach((it: any) => {
                const { reservationData, date } = it;
                if ((reservationData || []).length === 0) {
                    return;
                }
                const myReservations = reservationData.filter((x: any) => x.userId === this.currentUserId);
                const myReservationOverdue = myReservations.every((x: any) => x.endTime < currentTime);
                const allReservationOverdue = reservationData.every((x: any) => x.endTime < currentTime);
                it.myReservationOverdue = myReservationOverdue;
                it.allReservationOverdue = allReservationOverdue;
            });
        });
    }

    getCalendarNameWidth(): void {
        const width = this.commonService.getArrayMaxWidth(this.tableData, 'name');
        this.maxNameWidth = Math.max(width + 30, this.defaultNameWidth);
    }

    resetTableScroll(): void {
        const tableEl = this.table?.nzTableInnerScrollComponent?.tableBodyElement;
        const unit = this.calendarMode as OpUnitType;
        const currentDay = this.isDayMode ? this.start : this.currentDay;
        const isSame = dayjs(currentDay).startOf(unit).valueOf() === dayjs().startOf(unit).valueOf();
        const todayDate = dayjs().get('date');
        const startDate = dayjs(this.dateList[0]).get('date');
        const diffDate = todayDate - startDate;
        const beforeCurrentCount = !this.isDayMode ? (diffDate < 0 ? 0 : diffDate) : dayjs().get('hour') + 1;
        tableEl!.nativeElement!.scrollLeft = isSame ? beforeCurrentCount * this.cellWidth : 0;
    }

    async changeView(item: Tab): Promise<void> {
        dayjs.extend(isSameOrBefore);
        dayjs.extend(isSameOrAfter);
        const unit = item.id as OpUnitType;
        this.calendarMode = unit;
        const end = dayjs(this.end).format('YYYY-MM-DD');
        const start = dayjs(this.start).format('YYYY-MM-DD');
        const isBeforeEnd = dayjs().isSameOrBefore(end);
        const isAfterStart = dayjs().isSameOrAfter(start);
        this.currentDay = isAfterStart && isBeforeEnd ? dayjs().format('YYYY-MM-DD') : dayjs(this.start).format('YYYY-MM-DD');
        let range: number[] = [];
        if (this.isDayMode) {
            range = [dayjs(this.currentDay).startOf('day').valueOf(), dayjs(this.currentDay).endOf('day').valueOf()];
        } else {
            range = [dayjs(this.currentDay).startOf(unit).valueOf(), dayjs(this.currentDay).endOf(unit).valueOf()];
        }
        this.changeRange.emit(range);
    }

    tableMouseLeave(): void {
        this.selectDate = [];
        this.changeFilterVisible(false);
    }

    isSameDay(date1: string, date2: string | number, formatString: string = 'YYYY-MM-DD'): boolean {
        const day1 = dayjs(date1).format(formatString);
        const day2 = dayjs(date2).format(formatString);
        return dayjs(day1).isSame(day2);
    }

    // diffident user can select same request reservation
    hasSelfReservation(cell: any, type: string = 'all'): boolean {
        const reservationData = cell.reservationData || [];
        const arr = type === 'all' ? reservationData : (type === 'request' ? cell.requestReservations || [] : cell.reservedReservations || []);
        const hasSelf = arr.some((it: CalendarVOList) => it.userId === this.currentUserId);
        return hasSelf;
    }

    mouseDown(event: MouseEvent, data: any, cell: any): void {
        event.preventDefault();
        this.canEnterCell = true;
        const {
            selfValidRequest,
            selfValidReserved,
            otherValidRequest,
            otherValidReserved,
        } = this.getCellSelfReservation(cell);
        const canUsedReserved = this.calendarEditPermissionAll ? [...selfValidReserved, ...otherValidReserved] : selfValidReserved;
        const canUsedRequest = this.calendarEditPermissionAll ? [...selfValidRequest, ...otherValidRequest] : selfValidRequest;
        const canSelect = this.isCanSelect(cell, {
            canUsedRequest,
            canUsedReserved,
            otherValidReservations: [...otherValidReserved, ...otherValidRequest]
        });
        console.log('mouseDown', canSelect);
        if (cell.disabled || !this.canEdit || !canSelect) {
            return;
        }
        // tester cannot release other reserved or request
        const cellKey = `${data.id}_${cell.date}_${data.type}`;
        this.selectRowName = data.name;
        this.selectType = data.type !== 'UE' ? data.type.charAt(0) + data.type.slice(1).toLowerCase() : 'UE';
        this.selectDate.push(cellKey);
        this.startCell = cellKey;
        this.startInfo = cell;
        const isApproval = this.getIsApproval(data.type);
        // if first cell is reserved or self request ----> release
        const hasValidReserved = canUsedReserved.length > 0;
        const hasValidRequest = canUsedRequest.length > 0;
        this.dragCanUsedReserved = canUsedReserved;
        this.dragCanUsedRequest = canUsedRequest;
        if (hasValidReserved) {
            this.currentAction = 'release';
        } else if (hasValidRequest) {
            this.currentAction = 'cancelling';
        } else if (isApproval) {
            this.currentAction = 'request';
        } else {
            this.currentAction = 'reserve';
        }
        console.log('currentAction', this.currentAction);
    }

    getIsApproval(resourceType: string): boolean {
        const data = this.approvalSetting.find(item => item.type === resourceType.toUpperCase());
        return data ? data.approval : false;
    }

    // request release
    get isCancel(): boolean {
        return this.currentAction === 'cancelling';
    }

    getCellSelfReservation(cell: any): CellReservation {
        const reservations: CalendarVOList[] = cell.reservationData || [];
        const selfReservations: CalendarVOList[] = [];
        const selfReserved: CalendarVOList[] = [];
        const selfRequest: CalendarVOList[] = [];
        const otherReservations: CalendarVOList[] = [];
        const otherReserved: CalendarVOList[] = [];
        const otherRequest: CalendarVOList[] = [];
        const selfValidRequest: CalendarVOList[] = [];
        const selfValidReserved: CalendarVOList[] = [];
        const otherValidRequest: CalendarVOList[] = [];
        const otherValidReserved: CalendarVOList[] = [];
        const currentTime = this.getCurrentTime();
        reservations.forEach(it => {
            const { endTime, userId } = it;
            const status = it?.status;
            const isRequest = status && status === 'PENDING';
            const isSelf = userId === this.currentUserId;
            const isOverdue = endTime < currentTime;
            if (isSelf) {
                selfReservations.push(it);
                isRequest ? selfRequest.push(it) : selfReserved.push(it);
                if (isOverdue) {
                    return;
                }
                isRequest ? selfValidRequest.push(it) : selfValidReserved.push(it);
            } else {
                otherReservations.push(it);
                isRequest ? otherRequest.push(it) : otherReserved.push(it);
                if (isOverdue) {
                    return;
                }
                isRequest ? otherValidRequest.push(it) : otherValidReserved.push(it);
            }
            if (isOverdue) {
                return;
            }
        });

        return {
            selfReservations,
            selfReserved,
            selfRequest,
            otherReservations,
            otherReserved,
            otherRequest,
            otherValidReserved,
            otherValidRequest,
            selfValidReserved,
            selfValidRequest
        };
    }

    get cellDragUnit(): UnitType {
        return this.isDayMode ? 'h' : 'm';
    }

    getCurrentTime(): number {
        return dayjs().startOf(this.cellDragUnit).valueOf();
    }

    getSingleCellRange(cell: any): {
        startTime: number,
        endTime: number
    } {
        const date = cell.date;
        return {
            startTime: dayjs(date).startOf(this.cellUnit).valueOf(),
            endTime: dayjs(date).endOf(this.cellUnit).valueOf()
        };
    }

    isCanSelect(cell: any, reservationsInfo: {
        canUsedReserved: CalendarVOList[],
        canUsedRequest: CalendarVOList[],
        otherValidReservations: CalendarVOList[]
    }): boolean {
        const {
            canUsedReserved,
            canUsedRequest,
            otherValidReservations
        } = reservationsInfo;
        const notHasReservations = cell.reservationData.length === 0;
        if (this.calendarEditPermissionAll || notHasReservations) {
            return true;
        }
        if ([...canUsedReserved, ...canUsedRequest].length > 0) {
            return true;
        }
        if (!cell.isSomeHour) {
            return false;
        }
        const currentTime = this.getCurrentTime();
        const currentEndTime = dayjs().endOf(this.cellDragUnit).valueOf();
        const { startTime, endTime } = this.getSingleCellRange(cell);
        const cellEnd = Math.min(currentEndTime, endTime);
        const range = currentTime > startTime && currentTime < cellEnd ? [currentTime, cellEnd] : [startTime, cellEnd];
        const hasOtherReservationOccupied = otherValidReservations.find(x => x.startTime < range[1] && x.endTime > range[0]);
        if (!hasOtherReservationOccupied) {
            return true;
        }
        return false;
    }

    canEnter(cell: any): boolean {
        // const { selfReserved, selfRequest } = this.getCellSelfReservation(this.startInfo);
        const {
            selfValidRequest,
            selfValidReserved,
            otherValidRequest,
            otherValidReserved,
        } = this.getCellSelfReservation(cell);
        const enterCallCanUsedReserved = this.calendarEditPermissionAll ? [...selfValidReserved, ...otherValidReserved] : selfValidReserved;
        const enterCallCanUsedRequest = this.calendarEditPermissionAll ? [...selfValidRequest, ...otherValidRequest] : selfValidRequest;
        const startReservedId = this.dragCanUsedReserved?.[0]?.id;
        const canReserved = enterCallCanUsedReserved?.find(item => item.id === startReservedId);
        // first cell is reserved, enter cell is only the same reservedId   ---->   release
        console.log('startReservaed', startReservedId, enterCallCanUsedReserved);
        if (startReservedId && !canReserved) {
            return false;
        }
        const startRequestId = this.dragCanUsedRequest?.[0]?.id;
        const canRequest = enterCallCanUsedRequest?.find(item => item.id === startRequestId);
        // first cell is self request, enter cell is only the same requestId  ---->   release
        if (!startReservedId && startRequestId && !canRequest) {
            return false;
        }
        // first cell is empty or not self request, enter cell not select reserved or has self request  ---->   request/reserved
        if (!startReservedId && !startRequestId && (enterCallCanUsedReserved.length > 0 || enterCallCanUsedRequest?.length > 0)) {
            return false;
        }
        return true;
    }

    mouseEnter(event: MouseEvent, data: any, cell: any): void {
        event.preventDefault();
        // different reserved reservation can not mouse
        if (this.selectDate.length === 0 || cell.disabled || !this.canEnterCell || !this.canEnter(cell)) {
            this.canEnterCell = false;
            return;
        }

        const arr = this.startCell.split('_');
        const resourceId = arr[0];
        const startCellDate = arr[1];
        const type = arr[2];
        let startDate = startCellDate;
        let endDate = cell.date;
        if (dayjs(startCellDate).isAfter(cell.date)) {
            startDate = cell.date;
            endDate = startCellDate;
        }

        const diffUnit = this.isDayMode ? 'hour' : 'day';
        const diffCell = dayjs(endDate).diff(startDate, diffUnit);
        if (diffCell === 0) {
            this.selectDate = [resourceId + '_' + startDate];
            return;
        }
        this.selectDate = [
            `${resourceId}_${startDate}_${type}`,
            ...new Array(diffCell).fill(null).map((_, index) => {
                let date = '';
                if (this.calendarMode === 'day') {
                    date = dayjs(startDate).add(index + 1, diffUnit).format('YYYY-MM-DD HH:mm:ss');
                } else {
                    date = dayjs(startDate).add(index + 1, diffUnit).format('YYYY-MM-DD');
                }
                return `${resourceId}_${date}_${type}`;
            }),
        ];
    }

    async mouseUp(event: MouseEvent): Promise<void> {
        event.preventDefault();
        if (!this.startCell) {
            return;
        }
        const selectDate = this.selectDate;
        const isCancel = this.isCancel;
        const isRelease = this.currentAction === 'release';
        const isReserve = !isCancel && !isRelease;
        let text = isCancel ? `${this.currentAction} a request for` : `${this.currentAction.endsWith('e') ? this.currentAction.slice(0, -1) : this.currentAction}ing`;
        text = this.currentAction === 'request' ? text + ' a reservation for' : text;
        const modalRef: NzModalRef = this.modal.confirm({
            nzTitle: `You are ${text} the "${this.selectRowName}" ${this.selectType}.`,
            nzContent: isReserve && this.hasRequestApprovalPermission && this.calendarEditPermissionAll ? ReserveUserSelectComponent : '',
            nzComponentParams: {
                hasRequestApprovalPermission: this.hasRequestApprovalPermission,
                calendarEditPermissionAll: this.calendarEditPermissionAll
            },
            nzOkText: 'OK',
            nzCancelText: 'Cancel',
            nzOkType: 'primary',
            nzOkDanger: true,
            nzStyle: { top: '40vh' },
            nzOnOk: async () => {
                if (isReserve) {
                    const ref = modalRef.getContentComponent();
                    const desc = ref?.desc || '';
                    const assigneeUserId = ref?.chosenUserId;
                    await this.editReservation(selectDate, desc, undefined, assigneeUserId);
                } else {
                    // release and cancel
                    const reserved = this.startInfo?.reservedReservations || [];
                    const request = this.startInfo?.requestReservations || [];
                    const arr = isRelease ? reserved : request;
                    // Tester: only allowed to release/cancel reservation of his/her own.
                    // Resource Manager: can release/cancel any reservation. (Put his/her own as default ones)
                    const currentTime = dayjs().valueOf();
                    const validReservations = arr.filter(it => it.endTime >= currentTime);
                    if (validReservations.length === 0) {
                        this.message.warning(`All the reservations has expired.`);
                        return;
                    }
                    const myReservations = validReservations.filter(it => it.userId === this.currentUserId);
                    let startInfo = myReservations[0];
                    if (!startInfo?.id) {
                        if (this.calendarEditPermissionAll && arr && arr.length > 0) {
                            startInfo = validReservations[0];
                        } else {
                            return;
                        }
                    }
                    this.cancelOrReleaseReservationData = startInfo;
                    const ref = modalRef.getContentComponent();
                    const desc = ref?.desc || '';
                    await this.editReservation(selectDate, desc, startInfo);
                    // await this.resourceReservationService.deleteReservation(startInfoId);
                    // this.message.success(`Released successfully`);
                    // this.submitSuccess.emit();
                }
                modalRef.destroy();
            },
            nzOnCancel: () => {
                this.clearCell();
            },
        });
    }

    getDragCellDuration(startTime: string, endTime: string): {
        startTime: number,
        endTime: number
    } {
        let addedRange!: {
            startTime: number,
            endTime: number
        };
        const unit = this.cellUnit;
        // drag request ---> doesn't compare with current time
        // other actions need compare width current(release、reserved、request)
        const startIsCurrent = dayjs(startTime).startOf(unit).valueOf() === dayjs().startOf(unit).valueOf();
        addedRange = {
            startTime: !this.isCancel && startIsCurrent ? dayjs().startOf('m').valueOf() : dayjs(startTime).startOf(unit).valueOf(),
            endTime: dayjs(endTime).endOf(unit).valueOf(),
        };
        return addedRange;
    }

    getTimeValue(time: string, dateType: string = 'start'): number {
        if (dateType === 'start') {
            const maxStartTime = dayjs(this.start).isBefore(dayjs()) ? dayjs().valueOf() : this.start;
            const selectTime = dayjs(time).startOf('d').valueOf();
            const start = dayjs(maxStartTime).isBefore(selectTime) ? selectTime : maxStartTime;
            return dayjs(start).get('minute') >= 30 ? dayjs(start).add(1, 'h').startOf('h').valueOf() : dayjs(start).startOf('h').valueOf();
        } else {
            const end = dayjs(time).endOf('d').valueOf();
            return dayjs(this.end).isBefore(end) ? this.end : end;
        }
    }

    // judge use what api
    async releaseReservation(params: ReservationCancel): Promise<void> {
        const { startTime, endTime, id } = params;
        const originInfo = this.cancelOrReleaseReservationData;
        const originStart = originInfo?.startTime;
        const originEnd = originInfo?.endTime;
        if (startTime === originStart && originEnd === endTime) {
            await this.resourceReservationService.deleteReservation(id);
        } else {
            const isSameStart = startTime === originStart;
            const isSameEnd = endTime === originEnd;
            if ((isSameStart && !isSameEnd) || (isSameEnd && !isSameStart)) {
                if (isSameStart) {
                    params = {
                        ...params,
                        startTime: endTime + 1,
                        endTime: originEnd as number,
                    };
                }
                if (isSameEnd) {
                    params = {
                        ...params,
                        startTime: originStart as number,
                        endTime: startTime - 1,
                    };
                }
                await this.resourceReservationService.editReservation(params);
            } else {
                await this.resourceReservationService.cancelReservation(params);
            }
        }
        this.message.success(`Released successfully`);
    }

    getDragDateRange(selectDate: string[]): {
        startTime: string,
        endTime: string,
        resourceId: string,
        type: string
    } {
        const arr = selectDate[0].split('_');
        const resourceId = arr[0];
        const startTime = arr[1];
        const type = arr[2];
        const endTime = selectDate[selectDate.length - 1].split('_')[1];
        return {
            startTime,
            endTime,
            resourceId,
            type
        };
    }

    async editReservation(selectDate: string[], desc: string, info?: CalendarVOList, assigneeUserId?: number): Promise<void> {
        try {
            this.loading = true;
            const { resourceId, startTime, endTime, type} = this.getDragDateRange(selectDate);
            const addedRange = this.getDragCellDuration(startTime, endTime);
            let params: ReservationAdd = {
                startTime: addedRange.startTime,
                endTime: addedRange.endTime,
                type,
                description: desc,
            };
            if (assigneeUserId !== undefined) {
                params = {
                    ...params,
                    assigneeUserId
                };
            }

            if (type === 'CHAMBER') {
                params = {
                    ...params,
                    chamberId: Number(resourceId),
                };
            } else if (type === 'RADIO') {
                params = {
                    ...params,
                    radioId: Number(resourceId),
                };
            } else if (type === 'UE') {
                params = {
                    ...params,
                    ueId: Number(resourceId),
                };
            }
            // cancel or release
            if (info?.id) {
                const cellStart = addedRange.startTime;
                const cellEnd = addedRange.endTime;
                const infoStart = info?.startTime;
                const infoEnd = info?.endTime;
                const query = {
                    id: Number(info?.id),
                    description: desc,
                    startTime: Math.max(infoStart, cellStart),
                    endTime: Math.min(infoEnd, cellEnd)
                };
                await this.releaseReservation(query);
            } else {
                await this.resourceReservationService.addReservation(params);
                // If assignedUserId exists:
                // It indicate this operation is made by resource manager and would be reserved directly without need of approval.
                if (!assigneeUserId && this.currentAction === 'request') {
                    this.message.success(`Requested successfully`);
                } else {
                    this.message.success(`Reserved successfully`);
                }
            }
            this.submitSuccess.emit();
        } finally {
            this.loading = false;
            this.clearCell();
        }
    }

    clearCell(): void {
        this.selectDate = [];
        this.startCell = '';
        this.startInfo = null;
        this.dragCanUsedRequest = [];
        this.dragCanUsedReserved = [];
        this.canEnterCell = true;
    }

    getColor(cell: any): string {
        const level = cell.reservedReservations.length;
        const isPastDayColor = cell.disabled ? ' disabled-color' : '';
        return `cellColor${Math.min(5, level)} ${isPastDayColor}`;
    }

    getDisabledDate(dateItem: string): boolean {
        const disabledFormat = !this.isDayMode ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss';
        const unit = !this.isDayMode ? 'd' : 'h';
        const isDisabled = dayjs(dateItem).endOf(unit).isBefore(dayjs().format(disabledFormat)) || dayjs(dateItem).isAfter(this.end) || dayjs(dateItem).isBefore(dayjs(this.start).format(disabledFormat));
        return isDisabled;
    }

    getHeaderColor(dateItem: string): string {
        if (this.getDisabledDate(dateItem)) {
            return ' disabled-color';
        }

        const weekNumber = dayjs(dateItem).day();
        if (weekNumber === 6 || weekNumber === 0) {
            return 'weekend-cell';
        }
        return '';
    }

    async changeDayView(date: string): Promise<void> {
        if (this.isDayMode) {
            return;
        }
        this.calendarMode = 'day';
        this.currentDay = dayjs(date).format('YYYY-MM-DD');
        this.dateList = await this.getDateList();
        this.getResourceList();
    }

    changeFilterVisible(visible: boolean): void {
        this.filterVisible = visible;
    }

    getFilterParams(): ResourceParams {
        let filterParams: ResourceParams = {} as ResourceParams;
        const typeList: string[] = [];
        let allResourceChecked = true;
        this.nodes.forEach(it => {
            console.log('### it', it);
            const key = it.key;
            const allChecked = (it.children || []).every(item => item.checked);
            const someChecked = (it.children || []).some(item => item.checked);
            if (someChecked) {
                typeList.push(it.key);
            }
            if (!someChecked) {
                return;
            }
            const selectIds = (it.children || []).filter(item => item.checked).map(x => x.key.split('_')[1]);
            if (!allChecked) {
                allResourceChecked = false;
            }
            if (key === 'CHAMBER') {
                filterParams = {
                    ...filterParams,
                    chamberIdList: selectIds,
                };
            }
            if (key === 'RADIO') {
                filterParams = {
                    ...filterParams,
                    radioIdList: selectIds,
                };
            }
            if (key === 'UE' && this.hasUEPermission) {
                filterParams = {
                    ...filterParams,
                    ueIdList: selectIds,
                };
            }
        });
        return {
            ...allResourceChecked ? {} : filterParams,
            typeList,
        };
    }

    changeNodes(nodes: ResourceTree[]): void {
        this.nodes = nodes;
        this.changeFilter.emit();
    }

    edit(row: CalendarTableRow): void {
        const resourceType = row?.type;
        const isApproval = this.getIsApproval(resourceType);
        this.editDialog.open(row, isApproval, this.start, this.end);
    }

    editRefresh(): void {
        this.submitSuccess.emit();
    }
}
